import styled from '@emotion/styled';
import { flexColContainerStyles, HideOnMobile } from 'commonStyles';
import {
  DOM_STATE,
  TEXT_ALIGN,
  TEXT_TYPE,
  TSHIRT_SIZE,
} from 'components/constants';
import Cta from 'components/Cta';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  getThemeBrandedColor,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputPaddingShape,
  getThemeMainColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';
import { themev2 } from 'theme-v2';
import { theme } from 'theme-v2/theme';

export const StyledFeaturesContainer = styled.div<ITheme>((props) => ({
  maxWidth: '1024px',
  margin: '0 auto',
  marginTop: getThemeInputPaddingShape(props, TSHIRT_SIZE.XL),
}));

export const StyledList = styled.ul<ITheme>((props) => ({
  listStyle: 'none',
  display: CSS_DISPLAY.GRID,
  gridTemplateColumns: 'repeat(3, 1fr)',
  columnGap: getThemeGutter(props, TSHIRT_SIZE.L),
  padding: 0,
  [MEDIA_QUERIES.TABLET]: {
    columnGap: getThemeGutter(props, TSHIRT_SIZE.S),
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'repeat(3, 1fr)',
    rowGap: getThemeGutter(props, TSHIRT_SIZE.S),
  },
}));

export const ListItem = styled.li({
  ...flexColContainerStyles,
  padding: 0,
  textAlign: TEXT_ALIGN.CENTER,
});

export const FeatureTitle = styled.div<ITheme & { accentColor: string }>(
  ({ accentColor, ...props }) => ({
    color: accentColor || getThemeBrandedColor(props),
    'p, em, strong': {
      fontSize: getThemeFontSize(props, TEXT_TYPE.H4),
      fontWeight: 500,
      marginBottom: getThemeGutter(props),
    },
  })
);

export const FeatureDescription = styled.div((props) => ({
  color: getThemeMainColor(props, MAIN_COLOR.WHITE),
  'p, em, strong': {
    fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
    marginBottom: '10px',
  },
}));

export const HeroIcon = styled.div<ITheme>((props) => ({
  marginBottom: getThemeGutter(props),
  alignSelf: CSS_ALIGN.CENTER,
  div: {
    width: '60px',
    height: '60px',
  },
}));

export const StyledWrapper = styled.section<ITheme>((props) => ({
  background: themev2.colors.black.S900,
  width: '100%',
  padding: `${getThemeGutter(
    props,
    TSHIRT_SIZE.L
  )} 20px ${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)}`,
}));

export const StyledContainer = styled.div<ITheme>((props) => ({
  margin: '0 auto',
  marginTop: getThemeGutter(props, TSHIRT_SIZE.XL),
  maxWidth: '1024px',
  display: CSS_DISPLAY.GRID,
  gridTemplateColumns: '1fr 360px',
  alignItems: CSS_ALIGN.CENTER,
  columnGap: getThemeGutter(props, TSHIRT_SIZE.L),
  [MEDIA_QUERIES.TABLET]: {
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'repeat(2, auto)',
    justifyItems: CSS_JUSTIFY_CONTENT.CENTER,
    rowGap: 'unset',
    margin: '0 auto',
    marginTop: theme.spacings.L,
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    marginTop: theme.spacings.S,
  },
}));

export const StyledTextContainer = styled.div<ITheme>(
  ({ color, ...props }) => ({
    ...flexColContainerStyles,
    h1: {
      marginTop: getThemeGutter(props, TSHIRT_SIZE.XXS),
      marginBottom: getThemeGutter(props),
      color: color || getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
      [MEDIA_QUERIES.PHONE_PORTRAIT]: {
        marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
      },
    },
    p: {
      marginBottom: '27px',
      color: getThemeMainColor(props),
      a: {
        color: color || getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
        [DOM_STATE.HOVER]: {
          textDecoration: 'underline',
        },
      },
      ':last-of-type': {
        marginBottom: 0,
        [MEDIA_QUERIES.PHONE_PORTRAIT]: {
          marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
        },
      },
    },
    [MEDIA_QUERIES.TABLET]: {
      alignItems: CSS_ALIGN.CENTER,
      gridRow: 2,
      textAlign: TEXT_ALIGN.CENTER,
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      h4: {
        marginBottom: '28px',
      },
    },
  })
);

export const StyledCountdownTimerWrapper = styled.div({
  margin: '24px 0',
});

export const Title = styled.div<ITheme>(({ color, ...props }) => ({
  color: color || getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
  'h1, h2, h3': {
    marginTop: getThemeGutter(props, TSHIRT_SIZE.XXS),
    marginBottom: getThemeGutter(props),
    [MEDIA_QUERIES.TABLET]: {
      textAlign: TEXT_ALIGN.CENTER,
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
    },
  },
}));

export const Subtitle = styled.div<ITheme>(({ color, ...props }) => ({
  p: {
    fontFamily: getThemeFontFamily(props),
    marginBottom: '27px',
    color: getThemeMainColor(props),
    fontSize: getThemeFontSize(props, TEXT_TYPE.H4),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H4),
    fontWeight: 400,
    ':last-of-type': {
      marginBottom: 0,
    },
    a: {
      color: color || getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
      fontFamily: getThemeFontFamily(props),
      [DOM_STATE.HOVER]: {
        textDecoration: 'underline',
      },
    },
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    'h2, h3, h4': {
      marginBottom: '28px',
    },
  },
}));

export const CtaContainer = styled.div<ITheme>((props) => ({
  marginTop: getThemeGutter(props, TSHIRT_SIZE.S),
  display: CSS_DISPLAY.FLEX,
  a: {
    margin: 0,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    ...flexColContainerStyles,
    width: '100%',
    minWidth: 'unset',
    a: {
      width: '100%',
    },
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    marginTop: 0,
  },
}));

export const CtaPricing = styled(Cta)<ITheme>((props) => ({
  width: '220px',
  height: '52px',
  fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
  fontWeight: 500,
  textAlign: TEXT_ALIGN.CENTER,
  margin: 0,
  [MEDIA_QUERIES.TABLET]: {
    width: '220px',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    marginTop: getThemeGutter(props, TSHIRT_SIZE.S),
  },
}));

export const CtaStart = styled(CtaPricing)<ITheme>((props) => ({
  marginRight: getThemeGutter(props, TSHIRT_SIZE.S),
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    marginRight: 0,
  },
}));

export const ImageContainer = styled.div({
  margin: 'auto',
  ...HideOnMobile,
});

export const StyledReviews = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  alignItems: CSS_ALIGN.CENTER,
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  },
}));

export const StyledImageWrapper = styled.div<ITheme>((props) => ({
  marginRight: '6px',
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    marginRight: 0,
    marginBottom: getThemeGutter(props),
  },
}));

export const CtaWrapper = styled.div({
  width: '100%',
  maxWidth: 'max-content',
  [MEDIA_QUERIES.TABLET]: {
    textAlig: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    maxWidth: 'unset',
  },
});
